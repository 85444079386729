.landing-page {
  margin: 0 -30px;
}
@media only screen and (max-width: 991px) {
  .landing-page {
    margin: 0 -20px;
  }
}
.landing-page__wrapper {
  width: 1155px;
  margin: 0 auto;
  padding: 0 20px;
}
@media only screen and (max-width: 1154px) {
  .landing-page__wrapper {
    width: 100%;
  }
}
@media only screen and (max-width: 991px) {
  .landing-page__wrapper {
    text-align: center;
  }
}
.landing-page__wrapper.landing-page__wrapper_sm {
  width: 975px;
}
@media only screen and (max-width: 974px) {
  .landing-page__wrapper.landing-page__wrapper_sm {
    width: 100%;
  }
}
.landing-page__wrapper.landing-page__wrapper_md {
  width: 1040px;
}
@media only screen and (max-width: 1039px) {
  .landing-page__wrapper.landing-page__wrapper_md {
    width: 100%;
  }
}
.landing-page__wrapper.landing-page__wrapper_lg {
  width: 1090px;
}
@media only screen and (max-width: 1089px) {
  .landing-page__wrapper.landing-page__wrapper_lg {
    width: 100%;
  }
}
.landing-page__wrapper.landing-page__wrapper_100 {
  width: 100%;
}
.landing-page__wide-title {
  margin-left: 20px;
  margin-right: 20px;
}
.landing-page__hero {
  height: 675px;
  background: #5CB4BD url(/_next/static/files/hero-illustration_5630300a1a0230cc23314bf3e07abe01.svg) 100% 100% no-repeat;
}
@media only screen and (max-width: 1599px) {
  .landing-page__hero {
    background-size: 50vw;
  }
}
@media only screen and (max-width: 991px) {
  .landing-page__hero {
    height: auto;
    padding: 0 0 285px;
    background-size: 340px;
  }
}
.landing-page__hero .ant-btn {
  height: 70px;
  line-height: 70px;
  padding: 0 20px;
  font-weight: 700;
  font-size: 32px;
}
@media only screen and (max-width: 991px) {
  .landing-page__hero .ant-btn {
    height: 40px;
    line-height: 40px;
    padding: 0 25px;
    font-size: 16px;
  }
}
.landing-page__hero-slogan {
  width: 650px;
  line-height: 1.22;
  padding: 120px 0 0;
  color: #fff;
  font-size: 40px;
  font-weight: 700;
}
@media only screen and (max-width: 991px) {
  .landing-page__hero-slogan {
    width: 100%;
    padding: 60px 20px 20px;
    font-size: 26px !important;
  }
}
.landing-page__hero-text {
  width: 515px;
  line-height: 1.24;
  padding: 50px 0 75px;
  color: #fff;
  font-size: 28px;
  font-weight: 500;
}
@media only screen and (max-width: 991px) {
  .landing-page__hero-text {
    width: 100%;
    padding: 0 20px 20px;
    font-size: 16px;
  }
}
@media only screen and (max-width: 991px) {
  .landing-page_type-2 {
    margin: -20px -20px 0;
  }
}
.landing-page_type-2 .landing-page__hero {
  position: relative;
  height: 585px;
  background: url(/_next/static/files/hero-image_b057c84b2658e388dbf7b90e0a4ea56d.jpg) 50% 50% no-repeat;
  background-size: cover;
}
@media only screen and (max-width: 991px) {
  .landing-page_type-2 .landing-page__hero {
    height: auto;
    padding: 0 0 60px;
  }
}
.landing-page_type-2 .landing-page__hero .landing-page__wrapper {
  width: 100%;
  padding: 0 10px;
}
@media only screen and (min-width: 991px) {
  .landing-page_type-2 .landing-page__hero .ant-btn {
    position: absolute;
    bottom: 55px;
    left: 0;
    right: 0;
    width: 290px;
    margin: auto;
  }
}
.landing-page_type-2 .landing-page__hero-slogan {
  width: 100%;
  margin: 0;
  padding: 60px 0 0;
  line-height: 1.22;
  font-size: 52px;
  text-align: center;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35);
}
.landing-page_type-2 .landing-page__hero-text {
  width: 100%;
  padding: 30px 0 0;
  font-size: 28px;
  text-align: center;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35);
}
@media only screen and (max-width: 991px) {
  .landing-page_type-2 .landing-page__hero-text {
    padding: 30px 0 60px;
    font-size: 16px;
  }
}
.landing-page_type-project-manager .landing-page__hero {
  background-image: url(/_next/static/files/hero-illustration-personal-manager_d214653730d299b321e1efb69a307cc2.svg);
}
@media only screen and (min-width: 991px) {
  .landing-page_type-project-manager .landing-page__hero-slogan {
    font-size: 52px;
  }
}
@media only screen and (min-width: 991px) {
  .landing-page_type-project-manager .landing-page__hero-text {
    width: 550px;
    padding: 0 0 45px;
  }
}
@media only screen and (min-width: 991px) {
  .landing-page_type-project-manager .ant-btn {
    font-size: 22px;
  }
}
.landing-page__block {
  padding: 60px 0;
  background: #fff;
}
@media only screen and (max-width: 991px) {
  .landing-page__block {
    padding: 40px 0;
  }
}
.landing-page__block_gears-background {
  background: url(/_next/static/files/landing-background-gears-left_d3dce076c0daff528abaf21c81990ad1.svg) 0 50% no-repeat, url(/_next/static/files/landing-background-gears-right_8f4f5bd081f09a04a5a0033ca30b869d.svg) 100% 50% no-repeat, #fff;
}
@media only screen and (max-width: 991px) {
  .landing-page__block_gears-background {
    background-size: 45%, 35%;
    background-position: 0 0, 100% 0;
    background-repeat: repeat-y;
  }
}
.landing-page__block_gray {
  background: #F3F3F3;
}
.landing-page__block_testimonials-background {
  background: url(/_next/static/files/landing-background-testimonials-left_2e292431c6f68512e34b7ab8d39b26b0.svg) 0 50px no-repeat, url(/_next/static/files/landing-background-testimonials-right_02db742d122e6301c77b137048c66eb4.svg) right 117px top 17px no-repeat, #F3F3F3;
}
.landing-page__block h2 {
  line-height: 1.22;
  margin: 0 0 45px;
  color: #333333;
  font-size: 28px;
  font-weight: 700 !important;
  text-align: center;
}
@media only screen and (max-width: 991px) {
  .landing-page__block h2 {
    margin: 0 0 35px;
    font-size: 20px;
  }
}
.landing-page__action {
  margin: 60px 0 0;
  text-align: center;
}
@media only screen and (max-width: 991px) {
  .landing-page__action {
    margin: 40px 0 0;
  }
}
.landing-page__action .ant-btn {
  height: 60px;
  line-height: 54px;
  padding: 0 35px;
  border-width: 3px;
  font-size: 22px;
  font-weight: 700;
}
@media only screen and (max-width: 991px) {
  .landing-page__action .ant-btn {
    height: 40px;
    line-height: 36px;
    padding: 0 25px;
    border-width: 2px;
    font-size: 16px;
  }
}
.landing-page__techs,
.landing-page__executors,
.landing-page__customers,
.landing-page__personal-manager-tips {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-content: center;
          align-content: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin: 0 0 -45px;
}
@media only screen and (max-width: 991px) {
  .landing-page__techs,
  .landing-page__executors,
  .landing-page__customers,
  .landing-page__personal-manager-tips {
    margin: 0 0 -45px;
  }
}
.landing-page__tech {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex: 1 0 auto;
          flex: 1 0 auto;
  width: 40%;
  height: 84px;
  margin: 0 0 45px;
  padding: 0 20px 0 105px;
  background: no-repeat;
  color: #333333;
}
.landing-page__tech:nth-child(3n) {
  width: 20%;
}
@media only screen and (max-width: 991px) {
  .landing-page__tech {
    width: 100%;
    height: 60px;
    margin: 0 0 20px;
    padding: 0 20px 0 90px;
    background-size: contain;
    font-size: 15px;
    text-align: left;
  }
}
.landing-page__tech:hover,
.landing-page__tech:focus {
  color: #FE6054;
}
.landing-page__tech:nth-child(1) {
  background-image: url(/_next/static/files/landing-icon-tech-1_673af5d9925a6b7a6e4b237c0f3c78a6.svg);
}
.landing-page__tech:nth-child(2) {
  background-image: url(/_next/static/files/landing-icon-tech-2_19057a12934031a2d36798713a27b36b.svg);
}
.landing-page__tech:nth-child(3) {
  background-image: url(/_next/static/files/landing-icon-tech-3_df3e650f5d4c176246698c7df0180fe8.svg);
}
.landing-page__tech:nth-child(4) {
  background-image: url(/_next/static/files/landing-icon-tech-4_46e1c3935a66fc72b26adfb3a77e17ba.svg);
}
.landing-page__tech:nth-child(5) {
  background-image: url(/_next/static/files/landing-icon-tech-5_a664287f546d1ff977e752500ead7593.svg);
}
.landing-page__tech:nth-child(6) {
  background-image: url(/_next/static/files/landing-icon-tech-6_a13744e596c29474682b13015692ade7.svg);
}
.landing-page__tech:nth-child(7) {
  background-image: url(/_next/static/files/landing-icon-tech-7_247c45895b7a26fd2781df6fb47c3cf9.svg);
}
.landing-page__tech:nth-child(8) {
  background-image: url(/_next/static/files/landing-icon-tech-8_09a6597f366afe90085d16af4c042093.svg);
}
.landing-page__tech:nth-child(9) {
  background-image: url(/_next/static/files/landing-icon-tech-9_607d15822e17b04a18e1c257c3b1ad7d.svg);
}
.landing-page__executors {
  counter-reset: executors-counter;
}
.landing-page__executor {
  position: relative;
  -webkit-flex: 1 0 auto;
          flex: 1 0 auto;
  max-width: 46%;
  height: 290px;
  margin: 0 0 45px;
  padding: 35px 20px 0 100px;
  color: #333333;
  border-radius: 21px;
  -webkit-box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
          box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(8px);
  background: rgba(177, 225, 229, 0.5) 50% 100% no-repeat;
}
.landing-page__executor:nth-child(1) {
  background-image: url(/_next/static/files/landing-img-executor-1_d0cb32cb6f29619edfe749891aacf0cd.svg);
}
.landing-page__executor:nth-child(2) {
  background-image: url(/_next/static/files/landing-img-executor-2_3b8ecbf9e8e738578cd3f9a0c65334a6.svg);
}
.landing-page__executor:nth-child(3) {
  background-image: url(/_next/static/files/landing-img-executor-3_69785a009a28dc68ee815391cfbf4c95.svg);
}
.landing-page__executor:nth-child(4) {
  background-image: url(/_next/static/files/landing-img-executor-4_cb1a78de6a2f9065215bf81fef95330f.svg);
}
@media only screen and (max-width: 991px) {
  .landing-page__executor {
    width: 100%;
    max-width: 100%;
    height: auto;
    margin: 0 0 35px;
    padding: 70px 20px 160px;
  }
}
.landing-page__executor:after {
  content: counter(executors-counter);
  counter-increment: executors-counter;
  position: absolute;
  top: 20px;
  left: 20px;
  width: 55px;
  height: 55px;
  line-height: 55px;
  background: #fff;
  color: #7BA7AC;
  border-radius: 55px;
  font-size: 30px;
  font-weight: 500;
  text-align: center;
}
@media only screen and (max-width: 991px) {
  .landing-page__executor:after {
    left: 0;
    right: 0;
    width: 34px;
    height: 34px;
    line-height: 34px;
    margin: auto;
    font-size: 20px;
  }
}
.landing-page__executor-title {
  line-height: 1.22;
  margin: 0 0 10px;
  font-size: 20px;
  font-weight: 600;
}
@media only screen and (max-width: 991px) {
  .landing-page__executor-title {
    font-size: 18px;
  }
}
.landing-page__executor-description {
  line-height: 1.22;
  font-size: 15px;
  font-weight: 500;
}
@media only screen and (max-width: 991px) {
  .landing-page__executor-description {
    font-size: 13px;
  }
}
.landing-page__customers {
  counter-reset: customers-counter;
  margin: 0;
}
@media only screen and (max-width: 991px) {
  .landing-page__customers {
    margin: 0 0 -45px;
  }
}
.landing-page__customer {
  position: relative;
  -webkit-flex: 1 0 auto;
          flex: 1 0 auto;
  max-width: 32%;
  height: 355px;
  padding: 35px 20px 0 85px;
  color: #333333;
  border-radius: 21px;
  background: #fff 50% 100% no-repeat;
}
.landing-page__customer:nth-child(1) {
  background-image: url(/_next/static/files/landing-img-customer-1_2b29737321cf9aa6ce6d1bff72a6e11c.svg);
}
.landing-page__customer:nth-child(2) {
  background-image: url(/_next/static/files/landing-img-customer-2_f1356e0cb75476dacaed4ca036bbd0a2.svg);
}
.landing-page__customer:nth-child(3) {
  background-image: url(/_next/static/files/landing-img-customer-3_2b3e11f04284ec0dda9e108a3a74d376.svg);
}
.landing-page__customer br {
  content: ' ';
  display: block;
  margin: 5px 0;
}
@media only screen and (max-width: 991px) {
  .landing-page__customer {
    width: 100%;
    max-width: 100%;
    height: auto;
    margin: 0 0 35px;
    padding: 70px 20px 180px;
  }
}
.landing-page__customer:after {
  content: counter(customers-counter);
  counter-increment: customers-counter;
  position: absolute;
  top: 20px;
  left: 20px;
  width: 55px;
  height: 55px;
  line-height: 55px;
  background: #F3F3F3;
  color: #FE6054;
  border-radius: 55px;
  font-size: 30px;
  font-weight: 500;
  text-align: center;
}
@media only screen and (max-width: 991px) {
  .landing-page__customer:after {
    left: 0;
    right: 0;
    width: 34px;
    height: 34px;
    line-height: 34px;
    margin: auto;
    font-size: 20px;
  }
}
.landing-page__customer-title {
  height: 50px;
  line-height: 1.22;
  margin: 0 0 10px;
  font-size: 20px;
  font-weight: 600;
}
@media only screen and (max-width: 991px) {
  .landing-page__customer-title {
    height: auto;
    font-size: 18px;
  }
}
.landing-page__customer-description {
  line-height: 1.22;
  font-size: 15px;
  font-weight: 500;
}
@media only screen and (max-width: 991px) {
  .landing-page__customer-description {
    font-size: 13px;
  }
}
.landing-page__whys {
  margin: 80px 0 15px;
  padding: 0 0 0 20px;
  background: url(/_next/static/files/landing-img-why_efe46f814af19c311371bbe4d7c44cc6.svg) 100% 100% no-repeat;
}
@media only screen and (max-width: 991px) {
  .landing-page__whys {
    margin: 40px 0 15px;
    background: none;
  }
}
.landing-page__why {
  position: relative;
  width: 380px;
  padding: 0 0 0 65px;
  color: #333333;
  background: 0 5px no-repeat;
}
@media only screen and (max-width: 991px) {
  .landing-page__why {
    width: 100%;
    padding: 60px 0 0;
    background-position: 50% 0;
  }
}
.landing-page__why + .landing-page__why {
  margin: 20px 0 0;
}
@media only screen and (max-width: 991px) {
  .landing-page__why + .landing-page__why {
    margin: 45px 0 0;
  }
}
.landing-page__why:nth-child(1) {
  background-image: url(/_next/static/files/landing-icon-why-1_92ea194b6c84b163dc9dfcfed730617e.svg);
}
.landing-page__why:nth-child(2) {
  background-image: url(/_next/static/files/landing-icon-why-2_5e8a91f5b20dfaf488e5a7368b5be1c9.svg);
}
.landing-page__why:nth-child(3) {
  background-image: url(/_next/static/files/landing-icon-why-3_6aedd301257edd352962e1d61e84e828.svg);
}
.landing-page__why:nth-child(4) {
  background-image: url(/_next/static/files/landing-icon-why-4_83faebefae481a8fa033495a6614bf0c.svg);
}
.landing-page__why:nth-child(5) {
  background-image: url(/_next/static/files/landing-icon-why-5_e41a2671c063ac300996a033e40bd308.svg);
}
.landing-page__why-title {
  line-height: 1.25;
  margin: 0 0 5px;
  font-size: 20px;
  font-weight: 600;
}
@media only screen and (max-width: 991px) {
  .landing-page__why-title {
    margin: 0 0 10px;
    font-size: 18px;
  }
}
.landing-page__why-description {
  line-height: 1.3;
  font-size: 14px;
  font-weight: 500;
}
@media only screen and (max-width: 991px) {
  .landing-page__why-description {
    font-size: 13px;
  }
}
.landing-page__whys-personal-manager {
  margin: 40px auto;
  width: 525px;
}
@media only screen and (max-width: 767px) {
  .landing-page__whys-personal-manager {
    width: 100%;
  }
}
.landing-page__why-personal-manager {
  position: relative;
  padding: 0 0 0 75px;
  color: #333333;
  background: 0 5px no-repeat;
}
@media only screen and (max-width: 991px) {
  .landing-page__why-personal-manager {
    width: 100%;
    padding: 60px 0 0;
    background-position: 50% 0;
  }
}
.landing-page__why-personal-manager + .landing-page__why-personal-manager {
  margin: 35px 0 0;
}
@media only screen and (max-width: 991px) {
  .landing-page__why-personal-manager + .landing-page__why-personal-manager {
    margin: 45px 0 0;
  }
}
.landing-page__why-personal-manager:nth-child(1) {
  background-image: url(/_next/static/files/landing-icon-why-4_83faebefae481a8fa033495a6614bf0c.svg);
}
.landing-page__why-personal-manager:nth-child(2) {
  background-image: url(/_next/static/files/landing-icon-why-1_92ea194b6c84b163dc9dfcfed730617e.svg);
}
.landing-page__why-personal-manager:nth-child(3) {
  background-image: url(/_next/static/files/landing-icon-why-6_2506edfd5330762e6fd1cfe9885789cc.svg);
}
.landing-page__why-personal-manager:nth-child(4) {
  background-image: url(/_next/static/files/landing-icon-why-5_e41a2671c063ac300996a033e40bd308.svg);
}
.landing-page__why-personal-manager-title {
  line-height: 1.25;
  margin: 0 0 5px;
  font-size: 20px;
  font-weight: 600;
}
@media only screen and (max-width: 991px) {
  .landing-page__why-personal-manager-title {
    margin: 0 0 10px;
    font-size: 18px;
  }
}
.landing-page__personal-manager-tips {
  counter-reset: personal-manager-tips-counter;
}
.landing-page__personal-manager-tip {
  position: relative;
  -webkit-flex: 1 0 auto;
          flex: 1 0 auto;
  max-width: 46%;
  height: 340px;
  margin: 0 0 45px;
  padding: 35px 20px 0 100px;
  color: #333333;
  border-radius: 21px;
  -webkit-box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
          box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(8px);
  background: rgba(177, 225, 229, 0.5) 50% 100% no-repeat;
}
.landing-page__personal-manager-tip:nth-child(1) {
  background-image: url(/_next/static/files/landing-img-executor-1_d0cb32cb6f29619edfe749891aacf0cd.svg);
}
.landing-page__personal-manager-tip:nth-child(2) {
  background-image: url(/_next/static/files/landing-img-executor-2_3b8ecbf9e8e738578cd3f9a0c65334a6.svg);
}
.landing-page__personal-manager-tip:nth-child(3) {
  background-image: url(/_next/static/files/landing-img-executor-5_6eb709d8464f1c3551016afb94ac7ba3.svg);
  background-size: auto 145px;
}
.landing-page__personal-manager-tip:nth-child(4) {
  background-image: url(/_next/static/files/landing-img-executor-6_09181ba9297733743def8c2e3ee5f7e4.svg);
  background-size: auto 145px;
}
@media only screen and (max-width: 991px) {
  .landing-page__personal-manager-tip {
    width: 100%;
    max-width: 100%;
    height: auto;
    margin: 0 0 35px;
    padding: 70px 20px 160px;
  }
}
.landing-page__personal-manager-tip:after {
  content: counter(personal-manager-tips-counter);
  counter-increment: personal-manager-tips-counter;
  position: absolute;
  top: 20px;
  left: 20px;
  width: 55px;
  height: 55px;
  line-height: 55px;
  background: #fff;
  color: #7BA7AC;
  border-radius: 55px;
  font-size: 30px;
  font-weight: 500;
  text-align: center;
}
@media only screen and (max-width: 991px) {
  .landing-page__personal-manager-tip:after {
    left: 0;
    right: 0;
    width: 34px;
    height: 34px;
    line-height: 34px;
    margin: auto;
    font-size: 20px;
  }
}
.landing-page__personal-manager-tip-title {
  line-height: 1.22;
  margin: 0 0 10px;
  font-size: 20px;
  font-weight: 600;
}
@media only screen and (max-width: 991px) {
  .landing-page__personal-manager-tip-title {
    font-size: 18px;
  }
}
.landing-page__personal-manager-tip-description {
  line-height: 1.22;
  font-size: 15px;
  font-weight: 500;
}
@media only screen and (max-width: 991px) {
  .landing-page__personal-manager-tip-description {
    font-size: 13px;
  }
}
.landing-page__balloon {
  position: relative;
  max-width: 905px;
  padding: 10px 15px;
  border-radius: 10px;
  -webkit-box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
          box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
}
.landing-page__balloon:first-child {
  margin-top: 0;
}
.landing-page__balloon:before,
.landing-page__balloon:after {
  content: '';
  position: absolute;
  bottom: 0;
  width: 25px;
  height: 12px;
  background: inherit;
}
.landing-page__balloon:after {
  bottom: 1px;
  width: 15px;
  height: 15px;
  background: #fff;
}
.landing-page__balloon_question {
  margin-top: 15px;
  margin-left: 15px;
  background: #D3EAEC;
  font-weight: 700;
}
.landing-page__balloon_question:before,
.landing-page__balloon_question:after {
  left: -15px;
}
.landing-page__balloon_question:after {
  border-radius: 0 0 15px 0;
}
.landing-page__balloon_answer {
  margin-top: 20px;
  margin-right: 15px;
  margin-left: auto;
  background: #F7F7F9;
}
.landing-page__balloon_answer:before,
.landing-page__balloon_answer:after {
  right: -15px;
}
.landing-page__balloon_answer:after {
  border-radius: 0 0 0 15px;
}
.landing-page__balloon p {
  text-align: left;
}
@media only screen and (min-width: 991px) {
  .landing-page__balloon p {
    line-height: 1.47;
    font-size: 19px;
  }
}
.landing-page__balloon p + p {
  margin: 15px 0 0;
}
.landing-page .ant-btn-link {
  color: #FE6054 !important;
  font-size: inherit;
  font-weight: inherit !important;
}
.landing-page .ant-btn-link > span {
  text-decoration: underline;
}
.landing-page .ant-btn-link > span:hover,
.landing-page .ant-btn-link > span:focus {
  text-decoration: none;
}
.accent.accent_fountain {
  color: #5CB4BD;
}
.accent.accent_highlight {
  color: #FE6054;
}
.slider .slick-list {
  margin: -45px 0 15px;
  padding: 60px !important;
}
.slider .slick-next,
.slider .slick-prev {
  z-index: 2;
  top: 185px;
  width: 30px;
  height: 30px;
  margin: 0;
  background: url(/_next/static/files/landing-icon-arrow_eeb1dc797369b36c3a4c286ad9db686f.svg) !important;
  opacity: 0.7;
  -webkit-transition: opacity 250ms linear;
  transition: opacity 250ms linear;
}
.slider .slick-next:hover,
.slider .slick-prev:hover,
.slider .slick-next:focus,
.slider .slick-prev:focus {
  opacity: 1;
}
.slider .slick-next {
  left: -webkit-calc(50vw + 400px);
  left: calc(50vw + 400px);
  right: auto;
  right: initial;
}
.slider .slick-prev {
  right: -webkit-calc(50vw + 380px);
  right: calc(50vw + 380px);
  left: auto;
  left: initial;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.slider .slick-slide:not(.slick-active) {
  cursor: pointer;
}
.slider .slick-dots.slick-dots-bottom {
  bottom: 5px;
  margin: 0;
}
.slider .slick-dots li {
  width: 30px;
  height: 10px;
  margin: 0 7.5px;
}
.slider .slick-dots li button {
  height: 100%;
  background: #DEDEDE;
  border-radius: 30px;
  opacity: 1;
  opacity: initial;
}
.slider .slick-dots li button:hover,
.slider .slick-dots li button:focus {
  background: #7ba7ac99;
}
.slider .slick-dots li.slick-active {
  width: 30px;
}
.slider .slick-dots li.slick-active button {
  background: #7BA7AC;
}
.slider__slide {
  position: relative;
  width: 735px !important;
  height: 285px;
  padding: 25px;
  background: #fff;
  border-radius: 3px;
  -webkit-box-shadow: 1.6px 14.5px 48.1px rgba(0, 0, 0, 0.1);
          box-shadow: 1.6px 14.5px 48.1px rgba(0, 0, 0, 0.1);
  -webkit-transform: scale(118/147);
          transform: scale(118/147);
  -webkit-transition: all 500ms linear;
  transition: all 500ms linear;
  pointer-events: all;
}
.slider__slide:after {
  content: '';
  z-index: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.6);
  -webkit-transition: all 500ms linear;
  transition: all 500ms linear;
}
.slick-center .slider__slide {
  -webkit-transform: scale(1);
          transform: scale(1);
}
.slick-center .slider__slide:after {
  background: rgba(255, 255, 255, 0);
}
.slider__slide-header {
  margin: 0 0 25px;
  color: #333333;
  overflow: auto;
}
.slider__slide-header .ant-avatar {
  float: left;
  margin: 0 25px 0 0;
}
.slider__slide-name {
  line-height: 1.21;
  padding: 35px 0 5px;
  font-size: 19px;
  font-weight: 600;
}
.slider__slide-occupation {
  line-height: 1.21;
  font-size: 16px;
  font-weight: 500;
}
.slider__slide-testimonial {
  line-height: 1.21;
  color: #828282;
  font-size: 18px;
  font-weight: 500;
}
